body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-top: 65px;
  color: #282828;
  /* font-family: 'PT Sans Narrow', sans-serif;
;
font-family: 'Barlow Condensed', sans-serif;
font-family: 'Archivo Narrow', sans-serif; */
}
* {
  font-family: "Inconsolata", monospace;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
